
/*
* A very simple polyfill to get a kind of native bahavior of accordion element for
* browsers that doesn't support that.
*
* Basic usage instructions:
*
* 1. Include polyfill on your page (preferable in the end)
* 2. Initialize plugin with a simple one liner: accordion.init();
* 3. All the "flix-accordion" elements should now behave properly on a page
*
* Keep in mind that plugin automatically checks for native support of HTML <details> element and does nothing if it's in place.
*
 */

/*global define*/
/*eslint no-undef: ["error", { "typeof": true }] */

(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    // AMD. Register as an anonymous module.
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    // Browser globals (root is window)
    root.accordion = factory();
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function () {

  'use strict';

  var accordion = {};

  // selector for the elements to apply the polyfill to
  var elementSelector = '.flix-accordion';
  // indicates opening state, aligned with HTML specs to reflect the native behaviour (see: https://html.spec.whatwg.org/multipage/interactive-elements.html#the-details-element)
  var elementOpenAttr = 'open';
  // event to listen to
  var listeningEvent = 'click';
  // native details element listens to keyup,
  // using keydown in order to simplify code a bit and prevent page scrolling when spacebar being pressed
  // @todo provide a better solution if IE support is still needed
  var listeningKeyboardEvent = 'keydown';

  /**
   * Toggles open attribute on an element
   * @private
   */
  var toggleOpenState = function (event) {
    var element = event.currentTarget;

    if (!element.hasAttribute(elementOpenAttr)) {
      element.setAttribute(elementOpenAttr, '');
    } else {
      element.removeAttribute(elementOpenAttr);
    }
  };


  /**
   * Attaches event handlers to all elements found in the collection
   * @private
   */
  var attachEventHandlers = function (element) {
    element.addEventListener(listeningEvent,  toggleOpenState);
    // adding keyboard support
    element.addEventListener(listeningKeyboardEvent,  function (event) {
      var SPACE_KEY = 32;
      var ENTER_KEY = 13;
      // Toggle dropdown state with space bar and enter keys
      if (event.keyCode === SPACE_KEY || event.keyCode === ENTER_KEY) {
        event.preventDefault(); // disable page scroll when space bar is triggered
        toggleOpenState(event);
      }
    });
  };


  /**
   * Initialize the plugin
   */
  accordion.init =  function () {
    var activeElements = document.querySelectorAll(elementSelector);

    // since IE doesn't support forEach for NodeList, going old fashioned way
    for (var i = 0; i < activeElements.length; i++) {
      // check if browser has a native support for details element, open attribute needs to be supported
      if (typeof activeElements[i].open !== "boolean") {
        attachEventHandlers(activeElements[i]);
      }
    }
  };

  return accordion;
});
